import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PaymentsIcon from "@mui/icons-material/Payments";
import PixIcon from "@mui/icons-material/Pix";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { APP_CONFIG } from "../../constants/config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: APP_CONFIG.mainCollors.primary,
    display: "flex",
    flexDirection: "column",
    /* height: '200px', */
    padding: "20px",
    borderRadius: "17px",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: APP_CONFIG.mainCollors.primaryVariant,
    },
  },
}));

const CustomCard = ({ icon, title, ...rest }) => {
  const classes = useStyles();
  const { section } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.root}>
      {icon === "pagamentos" ? (
        <PaymentsIcon style={{ color: "white", fontSize: "60px" }} />
      ) : icon === "transferencias" ? (
        <CompareArrowsIcon style={{ color: "white", fontSize: "60px" }} />
      ) : icon === "pix" ? (
        <PixIcon style={{ color: "white", fontSize: "60px" }} />
      ) : icon === "boletos" ? (
        <ReceiptIcon style={{ color: "white", fontSize: "60px" }} />
      ) : null}
      <Typography
        style={{
          fontFamily: "Montserrat-ExtraBold",
          fontSize: "13px",
          color: "white",
          marginTop: "10px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default CustomCard;
