import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { makeStyles } from "@material-ui/styles";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable/CustomTable";
import { APP_CONFIG } from "../../constants/config";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {},
  },
  table: {
    [theme.breakpoints.down("sm")]: {},
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    boxSizing: "",
    fontSize: 17,
    fontFamily: "Montserrat-Regular",
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    color: APP_CONFIG.mainCollors.primary,
    [theme.breakpoints.down("sm")]: {},
  },
  body: {
    color: APP_CONFIG.mainCollors.primary,
    fontFamily: "Montserrat-Regular",
    fontSize: 15,

    [theme.breakpoints.down("sm")]: {},
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    },
    [theme.breakpoints.down("sm")]: {},
  },
}))(TableRow);

export default function PrintFolhaDePagamento() {
  const folhaDePagamento = useSelector((state) => state.folhaDePagamentoShow);
  const classes = useStyles();

  const columnsHeader = [
    {
      headerText: "",
      key: "",
      CustomValue: () => {
        return (
          <>
            <img src={APP_CONFIG.assets.smallColoredLogo}></img>
          </>
        );
      },
    },
    {
      headerText: "DESCRIÇÃO",
      key: "descricao",
      CustomValue: () => {
        return (
          <>
            <Typography
              style={{
                color: APP_CONFIG.mainCollors.primary,
                marginTop: "5px",
              }}
            >
              {folhaDePagamento.descricao}
            </Typography>
          </>
        );
      },
    },
    {
      headerText: "STATUS",
      key: "status_aprovado",
      CustomValue: () => {
        return (
          <>
            <Typography
              style={{
                color: APP_CONFIG.mainCollors.primary,
                marginTop: "5px",
              }}
            >
              {folhaDePagamento.status_aprovado}
            </Typography>
          </>
        );
      },
    },
    {
      headerText: "DATA DE PAGAMENTO",
      key: "data_pagamento",
      CustomValue: (data_pagamento) => {
        return (
          <>
            <Typography
              style={{
                color: APP_CONFIG.mainCollors.primary,
                marginTop: "5px",
              }}
            >
              {moment.utc(data_pagamento).format("DD MMMM YYYY")}
            </Typography>
          </>
        );
      },
    },
    {
      headerText: "",
      key: "menu",
    },
  ];

  const columns = [
    {
      headerText: "Nome",
      key: "conta.nome",
      CustomValue: (nome) => (
        <Typography style={{ lineBreak: "loose" }}>{nome}</Typography>
      ),
    },
    {
      headerText: "Agência",
      key: "conta.agencia",
      CustomValue: (documento) => (
        <Typography style={{ lineBreak: "anywhere" }}>{documento}</Typography>
      ),
    },
    {
      headerText: "Conta",
      key: "conta.conta",
      CustomValue: (celular) => (
        <Typography style={{ lineBreak: "anywhere" }}>{celular}</Typography>
      ),
    },
    // {
    // 	headerText: 'Email',
    // 	key: 'conta.email',
    // 	CustomValue: (email) => (
    // 		<Typography style={{ lineBreak: 'anywhere' }}>{email}</Typography>
    // 	),
    // },
    {
      headerText: "CPF",
      key: "conta.documento",
      CustomValue: (documento) => (
        <Typography style={{ lineBreak: "anywhere" }}>{documento}</Typography>
      ),
    },
    // {
    // 	headerText: 'Contato',
    // 	key: 'conta.celular',
    // 	CustomValue: (celular) => (
    // 		<Typography style={{ lineBreak: 'anywhere' }}>
    // 			{celular !== null ? celular : '*'}
    // 		</Typography>
    // 	),
    // },
    {
      headerText: "Tipo Pagamento",
      key: "tipo_pagamento",
      CustomValue: (tipo_pagamento) => (
        <Typography style={{ lineBreak: "loose" }}>{tipo_pagamento}</Typography>
      ),
    },
    {
      headerText: "Valor",
      key: "valor_pagamento",
      CustomValue: (valor) => (
        <Typography style={{ lineBreak: "loose" }}>
          R$
          {parseFloat(valor).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      ),
    },
    {
      headerText: "Status Transação",
      key: "status",
      CustomValue: (status) => (
        <Typography style={{ lineBreak: "loose" }}>{status}</Typography>
      ),
    },
  ];

  const Editar = (row) => {
    return (
      <>
        <Typography
          style={{
            color: APP_CONFIG.mainCollors.primary,
            fontSize: 17,
            fontFamily: "Montserrat-Regular",
          }}
        >
          IMPRIMIR
        </Typography>
        <Button onClick={() => window.print()}>
          <PrintIcon style={{ color: APP_CONFIG.mainCollors.primary }} />
        </Button>
      </>
    );
  };

  return (
    <Box>
      {folhaDePagamento ? (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columnsHeader.map((item) => (
                    <StyledTableCell
                      key={item.headerText}
                      align="center"
                      style={{
                        color: APP_CONFIG.mainCollors.primary,
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box>{item.headerText}</Box>
                        <Box>
                          {item.CustomValue
                            ? item.CustomValue(get(item, item.key))
                            : get(item, item.key)}
                        </Box>
                        <Box>{item.key === "menu" ? <Editar /> : null}</Box>
                        <Box>
                          {item.FullObject ? item.FullObject(item) : null}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          <CustomTable
            columns={columns ? columns : null}
            data={folhaDePagamento.funcionarios}
            Editar={Editar}
          />
        </>
      ) : (
        <Box width="60vw">
          <LinearProgress color="secondary" />
        </Box>
      )}
    </Box>
  );
}
