import {
  AppBar,
  Box,
  Dialog,
  FormHelperText,
  makeStyles,
  Modal,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import CurrencyInput from "react-currency-input";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import {
  delFavoritoPixAction,
  getConsultaChavePixAction,
  getFavoritosPixAction,
  postPagamentoPixAction,
  setRedirecionarValorTransferencia,
} from "../../actions/actions";
import { APP_CONFIG } from "../../constants/config";
import useAuth from "../../hooks/useAuth";
import CustomButton from "../CustomButton/CustomButton";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: " none",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    position: "absolute",

    top: "10%",
    left: "25%",
    /* transform: 'translate(-50%, -50%)', */
    width: "50%",
    height: "80%",
    backgroundColor: "white",
    /* bgcolor: 'background.paper', */
    border: "0px solid #000",
    boxShadow: 24,
    /* p: 5, */
  },
  currencyField: {
    fontFamily: "Montserrat-Regular",
    /* fontWeight: 'bold', */
    color: "white",
  },
  boxFavorito: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    padding: 10,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: APP_CONFIG.mainCollors.disabledTextfields,
      borderRadius: 27,
    },
  },
  subBoxFavorito: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    padding: 10,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: APP_CONFIG.mainCollors.disabledTextfields,
      borderRadius: 27,
    },
  },
  boxDeleteIcon: {
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E9C3C5",
      borderRadius: 27,
    },
  },
  chaveField: {
    fontFamily: "Montserrat-Thin",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    height: "45px",
    borderColor: `${APP_CONFIG.mainCollors.primary} !important`,
    borderRadius: 27,
    "&$cssFocused $notchedOutline": {
      borderWidth: 1,
    },
    "&:not($error) $notchedOutline": {
      borderColor: APP_CONFIG.mainCollors.primary,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },

    borderWidth: "1px",
    "& :-webkit-autofill": {
      "-webkit-padding-after": "15px",
      "-webkit-padding-before": "18px",
      "-webkit-padding-end": "15px",
      "-webkit-padding-start": "15px",
      "-webkit-background-clip": "text",
      "-webkit-color": "white",

      "-webkit-text-fill-color": "white !important",
    },

    "& $notchedOutline": {
      borderColor: "white",
      borderWidth: 1,
    },
    "&:hover $notchedOutline": {
      borderColor: "white",
      borderWidth: 1,
    },
    "&$focused $notchedOutline": {
      borderColor: "white",
      borderWidth: 1,
    },
    focused: {
      borderWidth: "1px",
    },
    notchedOutline: {
      borderWidth: "1px",
    },
    "&::placeholder": {
      fontFamily: "Montserrat-Thin",
      textOverflow: "ellipsis !important",
      color: APP_CONFIG.mainCollors.primary,
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
}));

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TransferirContainer = ({ title, changePath, ...rest }) => {
  const classes = useStyles();
  const { section } = useParams();
  const valorTransferencia = useSelector(
    (state) => state.redirecionarValorTransferencia
  );
  const consultaChave = useSelector((state) => state.consultaChave);
  const favoritosPix = useSelector((state) => state.favoritosPix);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = useAuth();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState("");
  const [value, setValue] = useState(0);
  const [openModalFavorito, setOpenModalFavorito] = useState(false);

  const [consultaChaveValidation, setConsultaChaveValidation] = useState(false);

  const [transferenciaPix, setTransferenciaPix] = useState({
    chave_recebedor: "",
    valor: valorTransferencia ? valorTransferencia : "",
    favorito: false,
    descricao: "",
    dataToken: "",
    nome: "",
    tipo: "",
  });

  const [infoFavoritos, setInfoFavoritos] = useState({
    nome: "",
    contas: [],
  });

  useEffect(() => {
    dispatch(getFavoritosPixAction(token));
  }, [token]);

  useEffect(() => {
    setTransferenciaPix({
      ...transferenciaPix,
      nome: consultaChave?.owner_name ?? "",
      tipo: consultaChave?.tipo_label ?? "",
    });
  }, [consultaChave]);

  useEffect(() => {
    return () => {
      dispatch(setRedirecionarValorTransferencia(null));
    };
  }, []);

  const handleConsultarChave = async (e) => {
    setLoading(true);
    const resConsulta = await dispatch(getConsultaChavePixAction(token, e));
    console.log(consultaChave);

    if (resConsulta === false) {
      toast.success("Chave encontrada!");
      setConsultaChaveValidation(true);
      setLoading(false);
    } else {
      toast.error("Falha na consulta da chave");
      setConsultaChaveValidation(false);
      setLoading(false);

      /* setConsultaChave({
				...consultaChave,

				nome: resConsulta.Infos.ReceiverName,
				tipo: resConsulta.Infos.PixKeyType,
			}); */
    }
  };

  const handlePagamentoPix = async (e) => {
    setLoading(true);
    const resPagamentoPix = await dispatch(
      postPagamentoPixAction(
        token,
        "Dict",
        transferenciaPix.tipo,
        chaveValue,
        transferenciaPix.valor,
        transferenciaPix.favorito,
        transferenciaPix.descricao,
        transferenciaPix.dataToken
      )
    );
    if (resPagamentoPix) {
      console.log(resPagamentoPix);
      setErrors(resPagamentoPix);
      setLoading(false);
    } else {
      toast.success("Pix enviado!");
      changePath("aprovacoes");
      setLoading(false);
      setOpenModal(false);
      //window.location.reload(false);
    }
  };
  const [chaveValue, setChaveValue] = useState("");
  const [mascara, setMascara] = useState("");

  useEffect(() => {
    const maskedValue = applyMask(chaveValue);
    setChaveValue(maskedValue);
  }, [chaveValue]);

  const applyMask = (value) => {
    if (value.includes("[a-zA-Z]")) {
      // Máscara para e-mail
      return value;
    } else if (isCPF(value)) {
      // Máscara para CPF
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, "$1.$2.$3-$4");
    } else if (value.length >= 14 && value.match("^[^a-zA-Z@]+$")) {
      // Máscara para CNPJ
      setMascara("99.999.999/9999-99");
      return value;
    } else if (value.length === 11 && value.match("^[^a-zA-Z@() ]+$")) {
      // Máscara para celular
      setMascara("(99) 99999-9999");
      return value;
    } else {
      // Chave aleatória (id) - não aplicar máscara
      setMascara("");
      return value;
    }
  };

  const isCPF = (value) => {
    const cpf = value.replace(/\D/g, ""); // Remove caracteres não numéricos do CPF

    if (cpf.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais (CPF inválido)
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    // Calcula o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digit = 11 - (sum % 11);
    if (digit === 10 || digit === 11) {
      digit = 0;
    }
    if (digit !== parseInt(cpf.charAt(9))) {
      return false;
    }

    // Calcula o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    digit = 11 - (sum % 11);
    if (digit === 10 || digit === 11) {
      digit = 0;
    }
    if (digit !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  };

  const isCNPJ = (value) => {
    const cnpj = value.replace(/\D/g, ""); // Remove caracteres não numéricos do CNPJ

    if (cnpj.length !== 14) {
      return false;
    }

    // Verifica se todos os dígitos são iguais (CNPJ inválido)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    // Calcula o primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj.charAt(i)) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    let digit = 11 - (sum % 11);
    if (digit < 0) {
      digit += 11;
    }
    if (digit !== parseInt(cnpj.charAt(12))) {
      return false;
    }

    // Calcula o segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj.charAt(i)) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    digit = 11 - (sum % 11);
    if (digit < 0) {
      digit += 11;
    }
    if (digit !== parseInt(cnpj.charAt(13))) {
      return false;
    }

    return true;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setChaveValue(inputValue);
  };

  const handleSetFavorito = (item) => {
    setMascara("");
    setLoading(true);

    setChaveValue(item.chave_recebedor);
    setValue(0);
    setOpenModalFavorito(false);
    setLoading(false);
    handleConsultarChave(item.chave_recebedor);
  };

  const handleDeleteFavorito = async (id) => {
    setLoading(true);
    const resDeleteFavorito = await dispatch(delFavoritoPixAction(token, id));
    if (resDeleteFavorito) {
      toast.error("Erro ao excluir contato dos favoritos");
      setLoading(false);
    } else {
      toast.success("Contato excluído dos favoritos");
      setLoading(false);
      setOpenModalFavorito(false);
      await dispatch(getFavoritosPixAction(token));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const getIndicatorColor = (index) =>
    index === value ? `2px solid ${APP_CONFIG.mainCollors.primary}` : null;

  return (
    <>
      <LoadingScreen isLoading={loading} />
      <Box
        display="flex"
        style={{
          marginTop: "10px",
          marginBottom: "16px",
          margin: 30,
        }}
      >
        <Box
          style={{
            width: "100%",
            borderTopRightRadius: 27,
            borderTopLeftRadius: 27,
          }}
        >
          <AppBar
            position="static"
            color="default"
            style={{
              backgroundColor: APP_CONFIG.mainCollors.backgrounds,
              boxShadow: "none",
              width: "100%",
              /* borderTopRightRadius: 27,
												borderTopLeftRadius: 27, */
            }}
          >
            <Tabs
              style={{
                color: APP_CONFIG.mainCollors.primary,
                width: "460px",
                boxShadow: "none",
              }}
              value={value}
              onChange={handleChangeTab}
              indicatorcolor={APP_CONFIG.mainCollors.primary}
              //textColor="primary"
              variant="fullWidth"
            >
              <Tab
                label="Transferir"
                style={{
                  width: "100%",
                  borderBottom: getIndicatorColor(0),
                }}
                {...a11yProps(0)}
              />

              <Tab
                label="Favoritos"
                style={{
                  width: "100%",
                  borderBottom: getIndicatorColor(1),
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* 
				<Box
					style={{
						width: '90%',
						height: '1px',
						backgroundColor: APP_CONFIG.mainCollors.primary,
					}}
				/> */}

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: APP_CONFIG.mainCollors.primary,
                      display: "flex",
                      flexDirection: "column",
                      /* height: '200px', */
                      padding: "20px",
                      borderRadius: "17px",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: 400,
                      /* justifyContent: 'center', */
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat-ExtraBold",
                        fontSize: "13px",
                        color: "white",
                        marginTop: "10px",
                      }}
                    >
                      Valor a transferir
                    </Typography>

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        style={{
                          marginTop: "20px",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <CurrencyInput
                          style={{
                            marginBottom: "6px",
                            width: "80%",
                            alignSelf: "center",
                            textAlign: "center",
                            height: 40,
                            fontSize: 20,
                            border: "none",
                            color: "#fff",
                            backgroundColor: "transparent",
                            fontFamily: "Montserrat-Regular",
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$ "
                          value={transferenciaPix.valor}
                          onChangeEvent={(event, maskedvalue, floatvalue) => {
                            setTransferenciaPix({
                              ...transferenciaPix,
                              valor: floatvalue,
                            });
                          }}
                        />
                        {errors.valor ? (
                          <FormHelperText
                            style={{
                              width: "300px",
                              fontSize: 14,
                              textAlign: "center",
                              fontFamily: "Montserrat-Regular",
                              color: "red",
                            }}
                          >
                            {errors.valor.join(" ")}
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat-Regular",
                      fontSize: "17px",
                      color: APP_CONFIG.mainCollors.primary,
                    }}
                  >
                    {consultaChaveValidation ?? consultaChave.owner
                      ? consultaChave.owner_name
                      : null}{" "}
                    -{" "}
                    {consultaChaveValidation ?? consultaChave.owner
                      ? consultaChave.tipo_label
                      : null}
                  </Typography>

                  <Box style={{ marginTop: "30px" }}>
                    <InputMask
                      style={{
                        color: APP_CONFIG.mainCollors.primary,
                        flexShrink: true,
                        width: "100%",
                        padding: "15px",
                        marginBottom: "5px",
                      }}
                      placeholderTextcolor={APP_CONFIG.mainCollors.primary}
                      placeholder="Email, CPF/CNPJ ou chave pix"
                      className={classes.chaveField}
                      fullWidth
                      variant="outlined"
                      error={
                        Array.isArray(errors.chave_recebedor)
                          ? errors.chave_recebedor.join(" ")
                          : errors.chave_recebedor
                      }
                      mask={mascara}
                      value={chaveValue}
                      onChange={handleChange}
                      maskPlaceholder={null}
                      onBlur={(e) => {
                        handleConsultarChave(e.target.value.replace("/", ""));
                      }}
                      checkText={(previous, next) => {
                        return next === `${previous} ` ? false : true;
                      }}
                    />
                    {/* <InputMask
											mask={mascara}
											value={chaveValue}
											onChange={handleChange}
											maskPlaceholder={null}
										/> */}
                  </Box>
                  <Box style={{ marginTop: "20px" }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Descrição"
                      value={transferenciaPix.descricao}
                      error={errors.descricao}
                      helperText={
                        errors.descricao ? errors.descricao.join(" ") : null
                      }
                      onChange={(e) => {
                        setTransferenciaPix({
                          ...transferenciaPix,
                          descricao: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      backgroundColor: APP_CONFIG.mainCollors.primary,
                      width: "20%",
                      borderRadius: "27px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat-Regular",
                        fontSize: "16px",
                        color: "white",
                      }}
                    >
                      Favoritar:
                    </Typography>

                    <Switch
                      /* style={{ color: APP_CONFIG.mainCollors.primary }} */
                      checked={transferenciaPix.favorito}
                      onChange={(e) => {
                        setTransferenciaPix({
                          ...transferenciaPix,
                          favorito: e.target.checked,
                        });
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                  }}
                >
                  <CustomButton
                    color="purple"
                    onClick={() => setOpenModal(true)}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat-Regular",
                        fontSize: "14px",
                        color: "white",
                      }}
                    >
                      Continuar
                    </Typography>
                  </CustomButton>
                </Box>
                <Modal
                  open={openModal}
                  onBackdropClick={() => setOpenModal(false)}
                >
                  <Box className={classes.modal}>
                    <LoadingScreen isLoading={loading} />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "30px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat-ExtraBold",
                          fontSize: "16px",
                          color: APP_CONFIG.mainCollors.primary,
                          fontWeight: "bold",
                        }}
                      >
                        Preencha o campo com o token do seu aplicativo.
                      </Typography>

                      <ReactCodeInput
                        value={transferenciaPix.dataToken}
                        onChange={(e) =>
                          setTransferenciaPix({
                            ...transferenciaPix,
                            dataToken: e,
                          })
                        }
                        type="number"
                        fields={6}
                        inputStyle={{
                          fontFamily: "monospace",
                          margin: "4px",
                          marginTop: "30px",
                          MozAppearance: "textfield",
                          width: "30px",
                          borderRadius: "28px",
                          fontSize: "20px",
                          height: "50px",
                          paddingLeft: "7px",

                          color: APP_CONFIG.mainCollors.primary,
                          border: `1px solid ${APP_CONFIG.mainCollors.primary}`,
                        }}
                      />
                      {errors.token ? (
                        <FormHelperText
                          style={{
                            fontSize: 14,
                            textAlign: "center",
                            fontFamily: "Montserrat-ExtraBold",
                            color: "red",
                          }}
                        >
                          {errors.token.join(" ")}
                        </FormHelperText>
                      ) : null}
                      {errors && errors.valor ? (
                        <FormHelperText
                          style={{
                            fontSize: 14,
                            textAlign: "center",
                            fontFamily: "Montserrat-ExtraBold",
                            color: "red",
                          }}
                        >
                          {errors.valor.join(" ")}
                        </FormHelperText>
                      ) : null}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "30px",
                        }}
                      >
                        <LoadingScreen isLoading={loading} />
                        <Box style={{ marginTop: "10px" }}>
                          <CustomButton
                            disabled={loading}
                            variant="contained"
                            color="purple"
                            style={{ marginTop: "10px" }}
                            onClick={handlePagamentoPix}
                          >
                            <Typography
                              style={{
                                fontFamily: "Montserrat-Regular",
                                fontSize: "14px",
                                color: "white",
                              }}
                            >
                              Enviar
                            </Typography>
                          </CustomButton>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          alignSelf: "center",
                          marginTop: "50px",
                        }}
                      >
                        <img
                          src={APP_CONFIG.assets.tokenImageSvg}
                          style={{ width: "80%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <>
                {favoritosPix && favoritosPix.length > 0 && (
                  <Typography style={{ color: APP_CONFIG.mainCollors.primary }}>
                    Todos os contatos:
                  </Typography>
                )}

                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {favoritosPix && favoritosPix.length > 0 ? (
                    <>
                      {favoritosPix.map((item) => (
                        <>
                          <Box
                            className={classes.boxFavorito}
                            onClick={() => {
                              setInfoFavoritos({
                                ...infoFavoritos,
                                nome: item.nome,
                                contas: item.contas,
                              });
                              setOpenModalFavorito(true);
                            }}
                          >
                            <Box
                              style={{
                                backgroundColor: APP_CONFIG.mainCollors.primary,
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                width: "50px",
                                borderRadius: "32px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <PersonIcon
                                style={{
                                  color: "white",
                                  fontSize: "30px",
                                }}
                              />
                            </Box>

                            <Typography
                              style={{
                                color: APP_CONFIG.mainCollors.primary,
                                marginLeft: "10px",
                              }}
                            >
                              {item.nome}
                            </Typography>
                          </Box>
                        </>
                      ))}
                    </>
                  ) : (
                    <Typography
                      style={{
                        color: APP_CONFIG.mainCollors.primary,
                      }}
                    >
                      Você não tem contatos favoritos.
                    </Typography>
                  )}
                  {/* 	{favoritosPix && favoritosPix[0] ? (
										<>
											{favoritosPix.map((item) => (
												<>
													<Box
														className={classes.boxFavorito}
														onClick={() => {
															setInfoFavoritos({
																...infoFavoritos,
																nome: item.nome,
																contas: item.contas,
															});
															setOpenModalFavorito(true);
														}}
													>
														<Box
															style={{
																backgroundColor:
																	APP_CONFIG.mainCollors
																		.primary,
																display: 'flex',
																flexDirection: 'column',
																height: '50px',
																width: '50px',
																borderRadius: '32px',
																alignItems: 'center',
																justifyContent: 'center',
															}}
														>
															<PersonIcon
																style={{
																	color: 'white',
																	fontSize: '30px',
																}}
															/>
														</Box>

														<Typography
															style={{
																color: APP_CONFIG.mainCollors
																	.primary,
																marginLeft: '10px',
															}}
														>
															{item.nome}
														</Typography>
													</Box>
												</>
											))}
										</>
									) : (
										<Typography
											style={{
												color: APP_CONFIG.mainCollors.primary,
											}}
										>
											Você não tem contatos favoritos.
										</Typography>
									)} */}
                </Box>
              </>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
      <Dialog
        open={openModalFavorito}
        onBackdropClick={() => setOpenModalFavorito(false)}
      >
        {infoFavoritos && infoFavoritos.contas && (
          <>
            <Box
              style={{
                padding: 30,
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
              }}
            >
              <Typography
                style={{
                  color: APP_CONFIG.mainCollors.primary,
                  fontSize: "20px",
                }}
              >
                {infoFavoritos.nome}
              </Typography>

              {infoFavoritos.contas.map((item) => (
                <>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      width={"90%"}
                      className={classes.subBoxFavorito}
                      onClick={() => handleSetFavorito(item)}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            color: APP_CONFIG.mainCollors.primary,
                            fontWeight: "bold",
                          }}
                        >
                          {item.tipo}
                        </Typography>
                        <Typography
                          style={{
                            color: APP_CONFIG.mainCollors.primary,
                          }}
                        >
                          {item.chave_recebedor}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"10%"}
                      className={classes.boxDeleteIcon}
                      onClick={() => handleDeleteFavorito(item.id)}
                    >
                      <DeleteIcon style={{ color: "#ED757D" }} />
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
};

export default TransferirContainer;
