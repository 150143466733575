import loginSvg from "../assets/vBankPJAssets/LoginSVG.svg";
import backgroundLogo from "../assets/vBankPJAssets/backgroundLogo.svg";
import faviconVbank from "../assets/vBankPJAssets/favicon/favicon.ico";
import tokenImageSvg from "../assets/vBankPJAssets/tokenImage.svg";
import smallColoredLogo from "../assets/vBankPJAssets/vBankSmallLogo.svg";
import smallWhiteLogo from "../assets/vBankPJAssets/vBankSmallLogoWhite.svg";
//vbank

import loginSvgAmazon from "../assets/amazonBankAssets/LoginSVG.svg";
import smallColoredLogoAmazon from "../assets/amazonBankAssets/amazonBankGreenLogo.svg";
import smallWhiteLogoAmazon from "../assets/amazonBankAssets/amazonBankLogo.svg";
import backgroundLogoAmazon from "../assets/amazonBankAssets/backgroundLogo.svg";
import faviconAmazon from "../assets/amazonBankAssets/favicon/favicon.ico";
import tokenImageSvgAmazon from "../assets/amazonBankAssets/tokenImage.svg";
//amazonbank

import backgroundLogoAcium from "../assets/aciumAssets/backgroundLogoAcium.svg";
import faviconAcium from "../assets/aciumAssets/favicon/favicon.ico";
import loginSvgAcium from "../assets/aciumAssets/loginSvgAcium.svg";
import smallBlackLogoAcium from "../assets/aciumAssets/smallBlackLogoAcium.svg";
import smallColoredLogoAcium from "../assets/aciumAssets/smallColoredLogoAcium.svg";
import smallWhiteLogoAcium from "../assets/aciumAssets/smallWhiteLogoAcium.svg";
import tokenImageAcium from "../assets/aciumAssets/tokenImageAcium.svg";
//acium

import backgroundLogoAprobank from "../assets/aprobankAssets/backgroundLogoAprobank.svg";
import faviconAprobank from "../assets/aprobankAssets/favicon/favicon.ico";
import loginSvgAprobank from "../assets/aprobankAssets/loginSvgAprobank.svg";
import smallColoredLogoAprobank from "../assets/aprobankAssets/smallColoredLogoAprobank.svg";
import smallWhiteLogoAprobank from "../assets/aprobankAssets/smallWhiteLogoAprobank.svg";
import tokenImageAprobank from "../assets/aprobankAssets/tokenImageAprobank.svg";
//aprobank

import backgroundLogoBelobank from "../assets/belobankAssets/backgroundLogoBelobank.svg";
import faviconBelobank from "../assets/belobankAssets/favicon/favicon.ico";
import loginSvgBelobank from "../assets/belobankAssets/loginSvgBelobank.svg";
import smallColoredLogoBelobank from "../assets/belobankAssets/smallColoredLogoBelobank.svg";
import smallWhiteLogoBelobank from "../assets/belobankAssets/smallWhiteLogoBelobank.svg";
import tokenImageBelobank from "../assets/belobankAssets/tokenImageBelobank.svg";
//belobank

import loginSvgSimer from "../assets/simerAssets/loginSvgSimer.svg";
import smallColoredLogoSimer from "../assets/simerAssets/smallColoredLogoSimer.svg";
import smallWhiteLogoSimer from "../assets/simerAssets/smallWhiteLogoSimer.svg";
//import backgroundLogoSimer from '../assets/simerAssets/backgroundLogoSimer.svg';
import faviconSimer from "../assets/simerAssets/favicon/favicon.ico";
import tokenImageSimer from "../assets/simerAssets/tokenImageSimer.svg";
//simerbank

import loginSvgPomelo from "../assets/pomeloAssets/loginSvgPomelo.svg";
import loginSvgPomeloPJ from "../assets/pomeloAssets/loginSvgPomeloPJ.svg";
import smallColoredLogoPomelo from "../assets/pomeloAssets/smallColoredLogoPomelo.svg";
/* import smallWhiteLogoPomelo from '../assets/pomeloAssets/smallWhiteLogoPomelo.svg'; */
import backgroundLogoPomelo from "../assets/pomeloAssets/backgroundLogoPomelo.svg";
import faviconPomelo from "../assets/pomeloAssets/favicon/favicon.ico";
import tokenImagePomelo from "../assets/pomeloAssets/tokenImagePomelo.svg";
//pomelo

import backgroundLogoXbank from "../assets/xbankAssets/backgroundLogoXbank.svg";
import faviconXbank from "../assets/xbankAssets/favicon/favicon.ico";
import loginSvgXbankPJ from "../assets/xbankAssets/loginSvgXbankPJ.svg";
import {
  default as loginSvgXbank,
  default as smallColoredLogoXbank,
} from "../assets/xbankAssets/smallColoredLogoXbank.svg";
import tokenImageXbank from "../assets/xbankAssets/tokenImageXbank.svg";
//xbank

import backgroundLogoConcorrencia from "../assets/ConcorrenciaAssets/backgroundLogoConcorrencia.svg";
import faviconConcorrencia from "../assets/ConcorrenciaAssets/favicon/favicon.ico";
import loginSvgConcorrencia from "../assets/ConcorrenciaAssets/loginSvgPJ.svg";
import loginSvgConcorrenciaPJ from "../assets/ConcorrenciaAssets/smallColoredLogoConcorrencia.svg";
import tokenImageConcorrencia from "../assets/ConcorrenciaAssets/tokenImage.svg";
//concorrencia

import faviconConcorrenciaFirminopolis from "../assets/ConcorrenciaFirminopolisAssets/favicon/favicon.ico";
import loginSvgConcorrenciaFirminopolis from "../assets/ConcorrenciaFirminopolisAssets/loginSvgPJ.svg";
import logoImgConcorrenciaFirminopolis from "../assets/ConcorrenciaFirminopolisAssets/logoImg.jpg";
import tokenImageConcorrenciaFirminopolis from "../assets/ConcorrenciaFirminopolisAssets/tokenImage.svg";
//Firminopolis

import faviconConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/favicon/favicon.ico";
import loginSvgConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/loginSvgPJ.svg";
import logoColoredConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/logoColored.svg";
import logoWhiteConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/logoWhite.svg";
import logotypeConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/logotype.svg";
import tokenImageConcorrenciaItaberai from "../assets/ConcorrenciaItaberaiAssets/tokenImage.svg";
//Itaberai

import faviconConcorrenciaItumbiara from "../assets/ConcorrenciaItumbiaraAssets/favicon/favicon.ico";
import loginSvgConcorrenciaItumbiara from "../assets/ConcorrenciaItumbiaraAssets/loginSvgPJ.svg";
import logoColoredConcorrenciaItumbiara from "../assets/ConcorrenciaItumbiaraAssets/logoColored.svg";
import logoWhiteConcorrenciaItumbiara from "../assets/ConcorrenciaItumbiaraAssets/logoWhite.svg";
import tokenImageConcorrenciaItumbiara from "../assets/ConcorrenciaItumbiaraAssets/tokenImage.svg";
//Itumbiara

import faviconConcorrenciaItapuranga from "../assets/ConcorrenciaItapurangaAssets/favicon/favicon.ico";
import loginSvgConcorrenciaItapuranga from "../assets/ConcorrenciaItapurangaAssets/loginSvgPJ.svg";
import logoImgConcorrenciaItapuranga from "../assets/ConcorrenciaItapurangaAssets/logoImg.png";
import tokenImageConcorrenciaItapuranga from "../assets/ConcorrenciaItapurangaAssets/tokenImage.svg";
//Itapuranga

import loginSvgBankzz from "../assets/bankzzAssets/smallColoredLogoBankzz.svg";
// import smallColoredLogoBankzz from '../assets/bankzzAssets/smallColoredLogoBankzz.svg';
/* import smallWhiteLogoBankzz from '../assets/BankzzAssets/smallWhiteLogoBankzz.svg'; */
import backgroundLoginBankzz from "../assets/bankzzAssets/backgroundLoginBankzz.svg";
import backgroundLogoBankzz from "../assets/bankzzAssets/backgroundLogoBankzz.svg";
import faviconBankzz from "../assets/bankzzAssets/favicon/favicon.ico";
import tokenImageBankzz from "../assets/bankzzAssets/tokenImageBankzz.svg";
//bankzz

export const APP_CONFIG = {
  vbank: {
    titleLogin: "V Bank",
    name: "V BANK - Dashboard do Banco V Bank",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "19e9aab6-292f-406c-8268-e45ea1a1dda3",
    sidebarRede: "Rede VBank",
    AbaCartoes: true,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.vbank",
    linkDePagamento: "https://banco.vbank.integrapay.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#4C4B97",
      primaryVariant: "#7776BC",
      secondary: "#9D9DC6",
      backgrounds: "#ECECF4",
      disabledTextfields: "#E0DFF8",
      extratoHome: "#302F60",
      primaryGradient:
        "linear-gradient(135deg, rgba(2,149,59,1) 15%, rgba(75,75,150,1) 100%)",
      secondaryGradient:
        "linear-gradient(360deg, rgba(2,149,59,1) 0%, rgba(75,75,150,1) 100%)",
      buttonGradient:
        "linear-gradient(135deg, rgba(2,149,59,1) 10%, rgba(75,75,150,1) 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, rgba(2,149,59,1) 15%, rgba(75,75,150,1) 100%)",
      drawerSideBar: "#02953b",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */

        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },
      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#4C4B97",
          light: "#EDEDF4",
        },
        secondary: {
          main: "#fff",
          light: "#fff",
        },
        tertiary: {
          main: "#fff",
          light: "#fff",
        },
      },
      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#4C4B97",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#4C4B97",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "white !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#4C4B97",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#4C4B97",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#4C4B97",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#4C4B97",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#4C4B97",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvg,
      loginLogoDireita: smallColoredLogo,
      smallColoredLogo: smallColoredLogo,
      smallWhiteLogo: smallWhiteLogo,
      backgroundLogo: backgroundLogo,
      tokenImageSvg: tokenImageSvg,
      favicon: faviconVbank,
    },
  },
  amazonbank: {
    titleLogin: "Amazon Banking Trust",
    name: "Amazon Banking Trust - Dashboard do Banco Amazon Banking Trust",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "7388cd2c-c4d0-4db6-988d-0f27075c291b",
    sidebarRede: "Rede Amazon Banking",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.amazonbank",
    linkDePagamento: "https://banco.amazonbankingtrust.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AmazonBank.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#0C4727",
      primaryVariant: "#299359",
      secondary: "#90BAA3",
      backgrounds: "#E7EDEA",
      disabledTextfields: "#90BAA3",
      extratoHome: "#123E26",
      primaryGradient:
        "linear-gradient(135deg, rgba(215,162,31,1) 15%, rgba(170,122,19,1) 100%)",
      secondaryGradient:
        "linear-gradient(360deg, rgba(215,162,31,1) 0%, rgba(170,122,19,1) 100%)",
      buttonGradient:
        "linear-gradient(135deg, rgba(215,162,31,1) 10%, rgba(170,122,19,1) 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, rgba(215,162,31,1) 15%, rgba(170,122,19,1) 100%)",
      drawerSideBar: "#d7a21f",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },
      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#0C4727",
          light: "#EDEDF4",
        },
        secondary: {
          main: "#fff",
          light: "#fff",
        },
        tertiary: {
          main: "#fff",
          light: "#fff",
        },
      },
      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#0C4727",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#0C4727",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "white !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#0C4727",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#0C4727",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#0C4727",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#0C4727",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#0C4727",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgAmazon,
      loginLogoDireita: smallColoredLogoAmazon,
      smallColoredLogo: smallColoredLogoAmazon,
      smallWhiteLogo: smallWhiteLogoAmazon,
      backgroundLogo: backgroundLogoAmazon,
      tokenImageSvg: tokenImageSvgAmazon,
      favicon: faviconAmazon,
    },
  },
  acium: {
    titleLogin: "Acium BNK Empresas",
    name: "Acium BNK Empresas",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede Acium BNK",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#3C3C3C",
      primaryVariant: "#262626",
      secondary: "#070707",
      backgrounds: "#F4F4F4",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#2B2B2B",
      primaryGradient:
        "linear-gradient(135deg, rgba(60,60,60) 15%, rgba(7,7,7) 100%)",
      secondaryGradient:
        "linear-gradient(360deg, rgba(60,60,60) 0%, rgba(7,7,7) 100%)",
      buttonGradient:
        "linear-gradient(135deg, rgba(60,60,60) 10%, rgba(7,7,7) 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, rgba(60,60,60) 15%, rgba(7,7,7) 100%)",
      drawerSideBar: "#3C3C3C",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#3C3C3C",
          light: "#3C3C3C",
        },
        secondary: {
          main: "#3C3C3C",
          light: "#3C3C3C",
        },
        tertiary: {
          main: "#3C3C3C",
          light: "#3C3C3C",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#3C3C3C",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#3C3C3C",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#3C3C3C !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#3C3C3C",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#3C3C3C",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#3C3C3C",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#3C3C3C",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#3C3C3C",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgAcium,
      /* loginLogoDireita: smallColoredLogoAcium, */
      loginLogoDireita: smallBlackLogoAcium,
      smallColoredLogo: smallColoredLogoAcium,
      smallWhiteLogo: smallWhiteLogoAcium,
      backgroundLogo: backgroundLogoAcium,
      tokenImageSvg: tokenImageAcium,
      favicon: faviconAcium,
    },
  },
  aprobank: {
    titleLogin: "Aprobank",
    name: "Aprobank - Dashboard do Banco Aprobank",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "c665b1fc-b3fc-4a3f-bfc9-0049b8005d33",
    sidebarRede: "Rede Aprobank",
    AbaCartoes: false,
    AbaAprovec: true,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aprobank",
    linkDePagamento: "https://banco.aprobank.integrapay.com.br",
    linkPdfTermoContrato:
      "https://d2b1r13l3o5i5x.cloudfront.net/termos/POLITICA_DE_PREVENCAO_A_LAVAGEM_DE_DINHEIRO_E_AO_FINANCIAMENTO_AO_TERRORISMO_-_INTTEGRAR_HOUSE.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#8D1812",
      primaryVariant: "#8D1812",
      secondary: "#161616",
      backgrounds: "#F2F2F2",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#161616",
      primaryGradient:
        "linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)",
      secondaryGradient:
        "linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)",
      buttonGradient:
        "linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, rgba(160, 35, 34,1) 15%, rgba(98, 2, 0,1) 100%)",
      drawerSideBar: "#620200",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#8D1812",
          light: "#8D1812",
        },
        secondary: {
          main: "#8D1812",
          light: "#8D1812",
        },
        tertiary: {
          main: "#8D1812",
          light: "#8D1812",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#8D1812",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#8D1812",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#8D1812 !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#8D1812",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#8D1812",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#8D1812",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#8D1812",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#8D1812",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgAprobank,
      loginLogoDireita: smallColoredLogoAprobank,
      smallColoredLogo: smallColoredLogoAprobank,
      smallWhiteLogo: smallWhiteLogoAprobank,
      backgroundLogo: backgroundLogoAprobank,
      tokenImageSvg: tokenImageAprobank,
      favicon: faviconAprobank,
    },
  },
  belobank: {
    titleLogin: "Belo Bank",
    name: "Belo Bank - Dashboard do Banco Belo Bank",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "6501131f-ae92-4c67-8f05-01e1d5ff2784",
    sidebarRede: "Rede Belo Bank",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#AF53FF",
      primaryVariant: "#AF53FF",
      secondary: "#AF53FF",
      backgrounds: "#FBF6FF",
      disabledTextfields: "#E0DFF8",
      extratoHome: "#9545DB",
      primaryGradient:
        "linear-gradient(135deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)",
      secondaryGradient:
        "linear-gradient(0deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)",
      buttonGradient:
        "linear-gradient(270deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)",
      buttonGradientVariant:
        "linear-gradient(135deg, rgba(68, 3, 91,1) 15%, rgba(223, 61, 194,1) 100%)",
      drawerSideBar: "#44035b",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#AF53FF",
          light: "#AF53FF",
        },
        secondary: {
          main: "#AF53FF",
          light: "#AF53FF",
        },
        tertiary: {
          main: "#AF53FF",
          light: "#AF53FF",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#AF53FF",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#AF53FF",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#AF53FF !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#AF53FF",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#AF53FF",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#AF53FF",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#AF53FF",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#AF53FF",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgBelobank,
      loginLogoDireita: smallColoredLogoBelobank,
      smallColoredLogo: smallColoredLogoBelobank,
      smallWhiteLogo: smallWhiteLogoBelobank,
      backgroundLogo: backgroundLogoBelobank,
      tokenImageSvg: tokenImageBelobank,
      favicon: faviconBelobank,
    },
  },
  simerbank: {
    titleLogin: "Simer Bank",
    name: "Simer - Dashboard do Banco Simer",
    description: "IntegraPAY – Soluções em pagamento para o seu negócio",
    crispId: "6501131f-ae92-4c67-8f05-01e1d5ff2784",
    sidebarRede: "Rede Simer Bank",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#205E6B",
      primaryVariant: "#205E6B",
      secondary: "#205E6B",
      backgrounds: "#F4F7F7",
      disabledTextfields: "#E0DFF8",
      extratoHome: "#227081",
      primaryGradient:
        "linear-gradient(180deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)",
      secondaryGradient:
        "linear-gradient(0deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)",
      buttonGradient:
        "linear-gradient(90deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)",
      buttonGradientVariant:
        "linear-gradient(270deg, rgba(32, 94, 107,1) 15%, rgba(255, 102, 0,1) 100%)",
      drawerSideBar: "#205e6b",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#205E6B",
          light: "#205E6B",
        },
        secondary: {
          main: "#205E6B",
          light: "#205E6B",
        },
        tertiary: {
          main: "#205E6B",
          light: "#205E6B",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#205E6B",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#205E6B",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#205E6B !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#205E6B",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#205E6B",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#205E6B",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#205E6B",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#205E6B",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgSimer,
      loginLogoDireita: smallColoredLogoSimer,
      smallColoredLogo: smallColoredLogoSimer,
      smallWhiteLogo: smallWhiteLogoSimer,
      backgroundLogo: "",
      tokenImageSvg: tokenImageSimer,
      favicon: faviconSimer,
    },
  },
  pomelo: {
    titleLogin: "Pomelo",
    name: "Pomelo",
    description: "Pomelo – Soluções em pagamento para o seu negócio",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede Pomelo",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#B91649",
      primaryVariant: "#198B8F",
      secondary: "#B91649",
      backgrounds: "#F2F2F2",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#B91649",
      primaryGradient: "linear-gradient(135deg, #CC1954 15%, #B91649 100%)",
      secondaryGradient: "linear-gradient(360deg, #CC1954 0%, #B91649 100%)",
      buttonGradient: "linear-gradient(135deg, #CC1954 10%, #B91649 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #CC1954 15%, #B91649 100%)",
      drawerSideBar: "#B91649",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#B91649",
          light: "#B91649",
        },
        secondary: {
          main: "#B91649",
          light: "#fff",
        },
        tertiary: {
          main: "#B91649",
          light: "#B91649",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#B91649",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#B91649",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#B91649 !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#B91649",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#B91649",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#B91649",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#B91649",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#B91649",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgPomeloPJ,
      /* loginLogoDireita: smallColoredLogoPomelo, */
      loginLogoDireita: smallColoredLogoPomelo,
      smallColoredLogo: smallColoredLogoPomelo,
      smallWhiteLogo: loginSvgPomelo,
      backgroundLogo: backgroundLogoPomelo,
      tokenImageSvg: tokenImagePomelo,
      favicon: faviconPomelo,
    },
  },
  xbank: {
    titleLogin: "xBank",
    name: "xBank",
    description: "xBank – Soluções em pagamento para o seu negócio",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede xBank",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.aciumbnk",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#0A43AD",
      primaryVariant: "#30DDFF",
      secondary: "#30DDFF",
      backgrounds: "#F3F3F3",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#0A43AD",
      primaryGradient: "linear-gradient(135deg, #E0E0E0 15%, #E0E0E0 100%)",
      secondaryGradient: "linear-gradient(360deg, #E0E0E0 0%, #E0E0E0 100%)",
      buttonGradient: "linear-gradient(135deg, #0A43AD 10%, #30DDFF 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #0A43AD 15%, #0A43AD 100%)",
      drawerSideBar: "#0A43AD",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#0A43AD",
          light: "#0A43AD",
        },
        secondary: {
          main: "#0A43AD",
          light: "#fff",
        },
        tertiary: {
          main: "#0A43AD",
          light: "#0A43AD",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgXbankPJ,
      loginLogoDireita: smallColoredLogoXbank,
      smallColoredLogo: smallColoredLogoXbank,
      smallWhiteLogo: loginSvgXbank,
      backgroundLogo: backgroundLogoXbank,
      tokenImageSvg: tokenImageXbank,
      favicon: faviconXbank,
    },
  },
  bankzz: {
    titleLogin: "Bankzz",
    name: "Bankzz",
    description: "Bankzz – Soluções em pagamento para o seu negócio",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede Bankzz",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: false,
    linkApp: "https://play.google.com/store/apps/details?id=br.com.pj.bankzz",
    linkDePagamento: "http://banco.bankzz.pj.integrapay.com.br",
    linkPdfTermoContrato:
      "https://d2b1r13l3o5i5x.cloudfront.net/termos/POLITICA_DE_PREVENCAO_A_LAVAGEM_DE_DINHEIRO_E_AO_FINANCIAMENTO_AO_TERRORISMO_-_INTTEGRAR_HOUSE.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#153B50",
      primaryVariant: "#DEC593",
      secondadry: "#DEC593",
      backgrounds: "#F3F5F6",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#153B50",
      primaryGradient: "linear-gradient(135deg, #153B50 15%, #153B50 100%)",
      secondaryGradient: "linear-gradient(360deg, #153B50 0%, #153B50 100%)",
      buttonGradient: "linear-gradient(135deg, #153B50 10%, #DEC593 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #DEC593 15%, #153B50 100%)",
      drawerSideBar: "#153B50",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#153B50",
          light: "#153B50",
        },
        secondary: {
          main: "#153B50",
          light: "#fff",
        },
        tertiary: {
          main: "#153B50",
          light: "#153B50",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgBankzz,
      /* loginLogoDireita: smallColoredLogoBankzz, */
      loginLogoDireita: backgroundLogoBankzz,
      smallColoredLogo: backgroundLogoBankzz,
      smallWhiteLogo: loginSvgBankzz,
      backgroundLogo: backgroundLoginBankzz,
      tokenImageSvg: tokenImageBankzz,
      favicon: faviconBankzz,
    },
  },
  concorrencia: {
    titleLogin: "concorrencia",
    name: "concorrencia",
    description: "concorrencia – Soluções em pagamento para o seu negócio",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede concorrencia",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: true,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.concorrencia",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#4FB645",
      primaryVariant: "#296423",
      secondary: "#296423",
      backgrounds: "#F3F3F3",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#4FB645",
      primaryGradient: "linear-gradient(135deg, #E0E0E0 15%, #E0E0E0 100%)",
      secondaryGradient: "linear-gradient(360deg, #E0E0E0 0%, #E0E0E0 100%)",
      buttonGradient: "linear-gradient(135deg, #4FB645 10%, #296423 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #057472 15%, #4FB645 100%)",
      drawerSideBar: "#4FB645",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#4FB645",
          light: "#4FB645",
        },
        secondary: {
          main: "#4FB645",
          light: "#fff",
        },
        tertiary: {
          main: "#4FB645",
          light: "#4FB645",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgConcorrencia,
      loginLogoDireita: loginSvgConcorrenciaPJ,
      smallColoredLogo: loginSvgConcorrenciaPJ,
      smallWhiteLogo: loginSvgConcorrenciaPJ,
      backgroundLogo: backgroundLogoConcorrencia,
      tokenImageSvg: tokenImageConcorrencia,
      favicon: faviconConcorrencia,
    },
  },
  firminopolis: {
    titleLogin: "Firminópolis",
    name: "Dashboard da prefeitura de Firminópolis",
    description: "Dashboard da prefeitura de Firminópolis",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede concorrencia",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: true,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.concorrencia",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#007A95",
      primaryVariant: "#64959F",
      secondary: "#F27C1A",
      backgrounds: "#f2f8fa",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#007A95",
      primaryGradient: "linear-gradient(135deg, #007A95 15%, #007A95 100%)",
      secondaryGradient: "linear-gradient(360deg, #007A95 0%, #007A95 100%)",
      buttonGradient: "linear-gradient(135deg, #F27C1A 10%, #F27C1A 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #057472 15%, #007A95 100%)",
      drawerSideBar: "#007A95",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#007A95",
          light: "#007A95",
        },
        secondary: {
          main: "#007A95",
          light: "#fff",
        },
        tertiary: {
          main: "#007A95",
          light: "#007A95",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgConcorrenciaFirminopolis,
      loginLogoDireita: logoImgConcorrenciaFirminopolis,
      smallColoredLogo: logoImgConcorrenciaFirminopolis,
      smallWhiteLogo: logoImgConcorrenciaFirminopolis,
      backgroundLogo: logoImgConcorrenciaFirminopolis,
      tokenImageSvg: tokenImageConcorrenciaFirminopolis,
      favicon: faviconConcorrenciaFirminopolis,
    },
  },
  itaberai: {
    titleLogin: "Itaberaí",
    name: "Dashboard da prefeitura de Itaberaí",
    description: "Dashboard da prefeitura de Itaberaí",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede concorrencia",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: true,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.concorrencia",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#00406F",
      primaryVariant: "#00406F",
      secondary: "#FD9F00",
      backgrounds: "#f2f5f8",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#00406F",
      primaryGradient: "linear-gradient(135deg, #FD9F00 15%, #FD9F00 100%)",
      secondaryGradient: "linear-gradient(360deg, #00406F 0%, #00406F 100%)",
      buttonGradient: "linear-gradient(135deg, #FD9F00 10%, #FD9F00 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #00406F 15%, #00406F 100%)",
      drawerSideBar: "#00406F",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#00406F",
          light: "#00406F",
        },
        secondary: {
          main: "#00406F",
          light: "#fff",
        },
        tertiary: {
          main: "#00406F",
          light: "#00406F",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgConcorrenciaItaberai,
      loginLogoDireita: logoColoredConcorrenciaItaberai,
      smallColoredLogo: logoColoredConcorrenciaItaberai,
      smallWhiteLogo: logoWhiteConcorrenciaItaberai,
      backgroundLogo: logotypeConcorrenciaItaberai,
      tokenImageSvg: tokenImageConcorrenciaItaberai,
      favicon: faviconConcorrenciaItaberai,
    },
  },
  itapuranga: {
    titleLogin: "Itapuranga",
    name: "Dashboard da prefeitura de Itapuranga",
    description: "Dashboard da prefeitura de Itapuranga",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede Itapuranga",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: true,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.concorrencia",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#2B5E2C",
      primaryVariant: "#2B5E2C",
      secondary: "#217437",
      backgrounds: "#f3f4f3",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#2B5E2C",
      primaryGradient: "linear-gradient(135deg, #1B6A97 15%, #1B6A97 100%)",
      secondaryGradient: "linear-gradient(360deg, #2B5E2C 0%, #2B5E2C 100%)",
      buttonGradient: "linear-gradient(135deg, #1B6A97 10%, #1B6A97 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #2B5E2C 15%, #2B5E2C 100%)",
      drawerSideBar: "#2B5E2C",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#2B5E2C",
          light: "#2B5E2C",
        },
        secondary: {
          main: "#2B5E2C",
          light: "#fff",
        },
        tertiary: {
          main: "#2B5E2C",
          light: "#2B5E2C",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgConcorrenciaItapuranga,
      loginLogoDireita: logoImgConcorrenciaItapuranga,
      smallColoredLogo: logoImgConcorrenciaItapuranga,
      smallWhiteLogo: logoImgConcorrenciaItapuranga,
      backgroundLogo: logoImgConcorrenciaItapuranga,
      tokenImageSvg: tokenImageConcorrenciaItapuranga,
      favicon: faviconConcorrenciaItapuranga,
    },
  },
  itumbiara: {
    titleLogin: "Itumbiara",
    name: "Dashboard da prefeitura de Itumbiara",
    description: "Dashboard da prefeitura de Itumbiara",
    crispId: "975a0211-1a18-4265-b253-df495ee372e6",
    sidebarRede: "Rede concorrencia",
    AbaCartoes: false,
    AbaAprovec: false,
    AbaBeneficiario: true,
    linkApp:
      "https://play.google.com/store/apps/details?id=br.com.pj.concorrencia",
    linkDePagamento: "https://banco.aciumbnk.com.br",
    linkPdfTermoContrato:
      "https://sandbox.fitbank.integrapay.com.br/termos/Termos_abertura_conta_digital_AciumBnk.pdf",
    areaTed: false,
    /* mailSupport: 'contato@integrapay.com.br', */

    datadog: {
      application_id: "0224154b858bfdbbe7785f17763c14a91c1c98d3",
      client_token: "pub301d88842724f58fe3cb2d6e79d7afa9",
    },

    mainCollors: {
      primary: "#003242",
      primaryVariant: "#003242",
      secondary: "#217437",
      backgrounds: "#f2f4f5",
      disabledTextfields: "#E9E9E9",
      extratoHome: "#003242",
      primaryGradient: "linear-gradient(135deg, #217437 15%, #217437 100%)",
      secondaryGradient: "linear-gradient(360deg, #003242 0%, #003242 100%)",
      buttonGradient: "linear-gradient(135deg, #217437 10%, #217437 100%)",
      buttonGradientVariant:
        "linear-gradient(180deg, #003242 15%, #003242 100%)",
      drawerSideBar: "#003242",
    },
    theme: {
      typography: {
        fontFamily: "Montserrat-Regular",
        /* fontWeight: 'bold', */
        h1: {
          fontFamily: "Montserrat-SemiBold",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        subtitle2: {
          fontFamily: "Montserrat-Regular",
          fontSize: "16px",
        },
        h4: {
          fontFamily: "Montserrat-SemiBold",
        },
      },

      palette: {
        background: {
          default: "#fff",
          paper: "#FFF",
        },
        primary: {
          main: "#003242",
          light: "#003242",
        },
        secondary: {
          main: "#003242",
          light: "#fff",
        },
        tertiary: {
          main: "#003242",
          light: "#003242",
        },
      },

      overrides: {
        MuiInputBase: {
          input: {
            fontFamily: "Montserrat-Regular",
            /* fontWeight: 'bold', */
            color: "#15191E",
          },
          label: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },

        MuiTableContainer: {
          root: {
            overflowX: "unset",
          },
        },

        MuiOutlinedInput: {
          root: {
            height: "45px",
            borderColor: "white",
            borderRadius: 27,
            "&$cssFocused $notchedOutline": {
              borderWidth: 1,
            },
            "&:not($error) $notchedOutline": {
              borderColor: "#15191E",

              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },

            borderWidth: "1px",
            "& :-webkit-autofill": {
              "-webkit-padding-after": "15px",
              "-webkit-padding-before": "18px",
              "-webkit-padding-end": "15px",
              "-webkit-padding-start": "15px",
              "-webkit-background-clip": "text",
              "-webkit-color": "white",

              "-webkit-text-fill-color": "#15191E !important",
            },

            "& $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&:hover $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
            "&$focused $notchedOutline": {
              borderColor: "white",
              borderWidth: 1,
            },
          },
          focused: {
            borderWidth: "1px",
          },
          notchedOutline: {
            borderWidth: "1px",
          },
          input: {
            "&::placeholder": {
              fontFamily: "Montserrat-SemiBold",
              textOverflow: "ellipsis !important",
              color: "black",
              /* fontWeight: '600', */
              fontSize: "23px",
            },

            borderRadius: "27px",
            height: "10px",
            color: "#15191E",
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
          },
        },

        MuiTextField: {
          root: {
            margin: "0px 0px 0px 0px",
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(14px, 15px) scale(1)",
            "&$shrink": {
              transform: "translate(14px, -20px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
          root: {
            transform: "translate(10px, 12px) scale(1)",
            "&$shrink": {
              transform: "translate(10px, -2px) scale(0.8)",
              color: "#15191E",
              fontFamily: "Montserrat-SemiBold",
            },
            fontFamily: "Montserrat-Thin",
            fontWeight: "bold",
            color: "#15191E",
          },
        },
        MuiButton: {
          contained: {
            fontFamily: "Montserrat-Thin",
            /* fontSize: '0.9rem', */
            textTransform: "none",
            boxShadow: "0px 0px 0px 0px",
          },

          fontFamily: "Montserrat-Thin",
          fontWeight: "bold",
        },
        MuiStepIcon: {
          color: "red",
          fill: "red",
          active: {
            color: "red",
            fill: "red",
          },
          completed: {
            color: "red",
            fill: "red",
          },
        },
      },
    },

    cssVariables: {
      gradient: {
        main: "linear-gradient(to right top, #cc9b00, #cc9b00);",
      },
    },
    assets: {
      loginSvg: loginSvgConcorrenciaItumbiara,
      loginLogoDireita: logoColoredConcorrenciaItumbiara,
      smallColoredLogo: logoColoredConcorrenciaItumbiara,
      smallWhiteLogo: logoWhiteConcorrenciaItumbiara,
      backgroundLogo: logoColoredConcorrenciaItumbiara,
      tokenImageSvg: tokenImageConcorrenciaItumbiara,
      favicon: faviconConcorrenciaItumbiara,
    },
  },
}[process.env.REACT_APP_FRONT_APP || "vbank"];
